import React from 'react';

const ClickIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="512"
      height="512"
      {...props}
    >
      <circle cx="256" cy="256" r="256" fill="#f0c419" />
      <path
        d="M256 106c-17.673 0-32 14.327-32 32v110h-26c-8.284 0-15 6.716-15 15v70c0 8.284 6.716 15 15 15h26v32c0 17.673 14.327 32 32 32s32-14.327 32-32v-32h26c8.284 0 15-6.716 15-15v-70c0-8.284-6.716-15-15-15h-26V138c0-17.673-14.327-32-32-32z"
        fill="#e67e22"
      />
    </svg>
  );
};

export default ClickIcon;