import React from 'react';

const SettingsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="32"
    height="32"
    fill="currentColor"
  >
    <path d="M19.14 12.936a7.966 7.966 0 0 0 0-1.872l2.047-1.584a.488.488 0 0 0 .119-.615l-1.938-3.352a.488.488 0 0 0-.596-.22l-2.416.97a8.061 8.061 0 0 0-1.616-.936l-.364-2.516a.488.488 0 0 0-.486-.416h-3.876a.488.488 0 0 0-.486.416l-.364 2.516a8.061 8.061 0 0 0-1.616.936l-2.416-.97a.488.488 0 0 0-.596.22l-1.938 3.352a.488.488 0 0 0 .119.615l2.047 1.584a7.966 7.966 0 0 0 0 1.872l-2.047 1.584a.488.488 0 0 0-.119.615l1.938 3.352c.122.211.386.295.596.22l2.416-.97c.5.374 1.045.684 1.616.936l.364 2.516c.035.243.243.416.486.416h3.876c.243 0 .451-.173.486-.416l.364-2.516c.571-.252 1.116-.562 1.616-.936l2.416.97c.211.075.474-.008.596-.22l1.938-3.352a.488.488 0 0 0-.119-.615l-2.047-1.584zM12 15.6a3.6 3.6 0 1 1 0-7.2 3.6 3.6 0 0 1 0 7.2z" />
  </svg>
);

export default SettingsIcon;
