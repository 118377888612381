const CoinIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 32 32"
      width="32"
      height="32"
    >
      <defs>
        {/* Добавь сюда любые градиенты или другие элементы, если нужно */}
      </defs>
      <circle cx="16" cy="16" r="14" fill="#FFD700" />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        fontSize="12"
        fill="#000"
        fontWeight="bold"
      >
        $
      </text>
    </svg>
  );
  
  export default CoinIcon;