import React from 'react';
import './theme.css';

const Income = ({ userId, incomePerHour, coins, setIncomePerHour, setCoins }) => {
  const handleUpgradeIncome = () => {
    if (coins >= 100) {
      setCoins((prevCoins) => prevCoins - 100);
      setIncomePerHour((prevIncome) => prevIncome + 720);

      fetch('/api/upgradeIncome', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, increase: 720, cost: 100 }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to upgrade income');
          }
          console.log('Income upgraded successfully');
        })
        .catch((error) => {
          console.error('Error upgrading income:', error);
        });
    } else {
      alert('Недостаточно монет для улучшения дохода');
    }
  };

  return (
    <div className="container">
      <h2 className="title">Доход в час</h2>
      <p className="income">Текущий доход: {incomePerHour} монет в час</p>
      <button className="upgradeButton" onClick={handleUpgradeIncome}>
        Увеличить доход (+720 монет в час) за 100 монет
      </button>
    </div>
  );
};

export default Income;
