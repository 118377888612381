import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import Header from './Header';
import './theme.css';
import CoinIcon from './icons/menu/CoinIcon';
import RankIcon from './icons/menu/RankIcon';
import SettingsIcon from './icons/menu/SettingsIcon';
import IncomeIcon from './icons/menu/IncomeIcon';
import AdminIcon from './icons/menu/AdminIcon';

const Layout = ({ coins, incomePerHour, role }) => {
  return (
    <div className="layout">
      <Header coins={coins} incomePerHour={incomePerHour} />
      <main className="mainContent">
        <Outlet />
      </main>
      <div className="bottomNav">
        <nav className="navHorizontal">
          <Link to="/" className="menuItem">
            <CoinIcon />
            <span className="menuLabel">Главная</span>
          </Link>
          <Link to="/rank" className="menuItem">
            <RankIcon />
            <span className="menuLabel">Рейтинг</span>
          </Link>
          <Link to="/settings" className="menuItem">
            <SettingsIcon />
            <span className="menuLabel">Настройки</span>
          </Link>
          <Link to="/income" className="menuItem">
            <IncomeIcon />
            <span className="menuLabel">Доход</span>
          </Link>
          {role === 'admin' && (
            <Link to="/admin" className="menuItem">
              <AdminIcon />
              <span className="menuLabel">Админ Панель</span>
            </Link>
          )}
        </nav>
      </div>
    </div>
  );
};

export default Layout;
