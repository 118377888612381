import React from 'react';

const AdminIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width="32"
    height="32"
    className={className}
  >
    <circle cx="16" cy="16" r="14" fill="#ffcc00" />
    <path
      d="M16 4c6.627 0 12 5.373 12 12S22.627 28 16 28 4 22.627 4 16 9.373 4 16 4zm0 2C10.486 6 6 10.486 6 16s4.486 10 10 10 10-4.486 10-10S21.514 6 16 6zm0 2c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm-6 14c0-3.313 4.418-4 6-4s6 .687 6 4v2H10v-2z"
      fill="#333"
    />
  </svg>
);

export default AdminIcon;
