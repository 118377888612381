import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './theme.css';
import LeftArrowIcon from './icons/rank/arrow/LeftArrowIcon';
import RightArrowIcon from './icons/rank/arrow/RightArrowIcon';

const Rank = ({ userId, initialLevel, setLevel }) => {
  const [ranking, setRanking] = useState([]);
  const [level, localSetLevel] = useState(initialLevel || 1);

  useEffect(() => {
    console.log("Rank Component Props:", { userId, initialLevel, setLevel });
  }, [userId, initialLevel, setLevel]);

  useEffect(() => {
    if (initialLevel) {
      localSetLevel(initialLevel);
    }
  }, [initialLevel]);

  useEffect(() => {
    fetchRanking(level);
  }, [level]);

  const fetchRanking = async (level) => {
    try {
      console.log(`Fetching ranking for level ${level}`);
      const response = await fetch(`/api/ranking?level=${level}`);
      if (!response.ok) {
        throw new Error('Failed to fetch ranking data');
      }
      const rankingData = await response.json();
      console.log(`Полученные данные для уровня ${level}:`, rankingData);
      setRanking(rankingData);
    } catch (error) {
      console.error('Ошибка при получении рейтинга:', error);
    }
  };

  const handleNextLevel = () => {
    if (level < 4) {
      localSetLevel((prevLevel) => prevLevel + 1);
      setLevel((prevLevel) => prevLevel + 1);
    }
  };

  const handlePreviousLevel = () => {
    if (level > 1) {
      localSetLevel((prevLevel) => prevLevel - 1);
      setLevel((prevLevel) => prevLevel - 1);
    }
  };

  return (
    <div className="container">
      <motion.h2
        className="title"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        Рейтинг
      </motion.h2>
      <div className="levelContainer">
        {level > 1 && (
          <button className="navButton" onClick={handlePreviousLevel}>
            <LeftArrowIcon />
          </button>
        )}
        <span className="levelText">Уровень {level}</span>
        {level < 4 && (
          <button className="navButton" onClick={handleNextLevel}>
            <RightArrowIcon />
          </button>
        )}
      </div>

      {ranking.length > 0 ? (
        <motion.table
          className="rankingTable"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <thead>
            <tr>
              <th>Порядковый номер</th>
              <th>ID пользователя</th>
              <th>Доход в час</th>
            </tr>
          </thead>
          <tbody>
            {ranking.map((user, index) => (
              <motion.tr
                key={user.id}
                className={user.id === userId ? 'currentUserRow' : ''}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: index * 0.1 }}
              >
                <td>{index + 1}</td>
                <td>{user.id}</td>
                <td>{user.income_per_hour}</td>
              </motion.tr>
            ))}
          </tbody>
        </motion.table>
      ) : (
        <p className="noDataMessage">Нет данных для выбранного уровня</p>
      )}
    </div>
  );
};

export default Rank;
