import React from 'react';
import { motion } from 'framer-motion';
import './theme.css';

const Settings = ({ theme, toggleTheme }) => {
  return (
    <motion.div
      className="container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="title">Настройки</h2>
      <p className="settingsInfo">Здесь будут дополнительные настройки в будущем.</p>
      <button onClick={toggleTheme} className="toggleButton">
        Переключить тему на {theme === 'light' ? 'тёмную' : 'светлую'}
      </button>
    </motion.div>
  );
};

export default Settings;