import React from 'react';

const IncomeIcon = ({ width = 32, height = 32 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={width}
    height={height}
    fill="currentColor"
  >
    <path d="M16 2C8.82 2 3 7.82 3 15c0 6.08 4.33 11.16 10 12.41V28h6v-0.59C24.67 26.16 29 21.08 29 15 29 7.82 23.18 2 16 2zm0 2c5.52 0 10 4.48 10 10 0 5.06-3.77 9.27-8.78 9.93L17 25h-2l-0.22-1.07C9.77 23.27 6 19.06 6 14c0-5.52 4.48-10 10-10zm0 3a7 7 0 100 14 7 7 0 000-14zm0 2a5 5 0 110 10 5 5 0 010-10z" />
  </svg>
);

export default IncomeIcon;