import React from 'react';
import { motion } from 'framer-motion';
import './theme.css';

const Header = ({ coins, incomePerHour }) => {
  return (
    <div className="header">
      <motion.div
        className="clickCount coins"
        initial={{ scale: 1 }}
        animate={{ scale: 1.1 }}
        transition={{ duration: 0.2 }}
        key={coins}
      >
        Количество монет: {parseInt(coins, 10)}
      </motion.div>
      <div className="incomePerHour">
        Доход в час: {incomePerHour}
      </div>
    </div>
  );
};

export default Header;
