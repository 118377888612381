const RatingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    width="32"
    height="32"
  >
    <defs>
      {/* Если в SVG были градиенты или другие элементы внутри <defs>, убедись, что они корректно вставлены */}
    </defs>
    <path
      d="M16 2.5L19.09 10.36L27.5 11.27L21.08 17.02L22.97 25.27L16 20.97L9.03 25.27L10.92 17.02L4.5 11.27L12.91 10.36L16 2.5Z"
      fill="#FFD700"
    />
  </svg>
);

export default RatingIcon;
