import React from 'react';
import './theme.css';

const AdminPanel = () => {
  return (
    <div className="container adminPanelContainer">
      <h2 className="title">Admin Panel</h2>
      <div className="panelContent">
        <p>Welcome, Admin! Here you can manage the application and user data.</p>
        {/* Добавьте здесь любую функциональность, которую требуется предоставить администраторам */}
      </div>
    </div>
  );
};

export default AdminPanel;
