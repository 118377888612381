import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './theme.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Rank from './Rank';
import Settings from './Settings';
import Layout from './Layout';
import ClickIcon from './icons/click/ClickIcon';
import Income from './Income';
import AdminPanel from './AdminPanel';

const Home = ({ userId, setCoins, setLevel }) => {
  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        try {
          console.log(`Fetching data for userId: ${userId}`);
          const response = await fetch(`/api/user?userId=${userId}`);
          if (!response.ok) throw new Error('Failed to fetch user data');
          const result = await response.json();
          console.log('User data received:', result);
          setCoins(parseInt(result.coins, 10));
          setLevel(parseInt(result.level, 10));
        } catch (error) {
          console.error('Ошибка при получении данных пользователя:', error);
        }
      };
      fetchData();
    }
  }, [userId, setCoins, setLevel]);

  const handleClick = async () => {
    if (!userId) {
      console.error('User ID is not available');
      return;
    }

    try {
      const response = await fetch('/api/coin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, coinCount: 1 }),
      });
      if (!response.ok) throw new Error('Failed to record coin');
      console.log('Coin recorded successfully');
      updateCoinsAndLevel(1);
    } catch (error) {
      console.error('Error clicking button:', error);
    }
  };

  const updateCoinsAndLevel = (increment) => {
    setCoins((prevCoins) => {
      const newCoins = parseInt(prevCoins, 10) + increment;
      const newLevel = determineLevel(newCoins);
      setLevel(newLevel);
      return newCoins;
    });
  };

  const determineLevel = (coins) => {
    if (coins >= 0 && coins <= 50) return 1;
    if (coins >= 51 && coins <= 125) return 2;
    if (coins >= 126 && coins <= 300) return 3;
    return 4;
  };

  return (
    <div className="container">
      <h1 className="title">Welcome</h1>
      <motion.div
        className="clickIconWrapper"
        onClick={handleClick}
        whileTap={{ scale: 0.9 }}
      >
        <ClickIcon className="clickIcon" />
      </motion.div>
    </div>
  );
};

function App() {
  const [userId, setUserId] = useState(null);
  const [level, setLevel] = useState(1);
  const [coins, setCoins] = useState(0);
  const [incomePerHour, setIncomePerHour] = useState(0);
  const [role, setRole] = useState('user');
  const [theme, setTheme] = useState('light');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadTelegramScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-web-app.js';
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    loadTelegramScript()
      .then(() => {
        const tg = window.Telegram?.WebApp;
        if (tg) {
          tg.ready();
          console.log('Telegram WebApp initialized');
          const user = tg.initDataUnsafe?.user;
          if (user) {
            console.log(`User ID from Telegram: ${user.id}`);
            setUserId(user.id);
          }
        } else {
          console.warn('Telegram WebApp не инициализирован');
        }
      })
      .catch((error) => {
        console.error('Ошибка при загрузке скрипта Telegram WebApp:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const fetchUserIncomeAndRoleAndTheme = async () => {
      if (userId) {
        try {
          const response = await fetch(`/api/user?userId=${userId}`);
          if (!response.ok) throw new Error('Failed to fetch user data');
          const result = await response.json();
          setIncomePerHour(parseInt(result.income_per_hour, 10));
          setRole(result.role);
          setTheme(result.theme || 'light');
          document.body.className = result.theme || 'light';
        } catch (error) {
          console.error('Ошибка при получении данных пользователя:', error);
        }
      }
    };
    fetchUserIncomeAndRoleAndTheme();
  }, [userId]);

  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
  }, [theme]);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
      document.body.className = savedTheme;
    }
  }, []);

  const toggleTheme = async () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);

    if (userId) {
      try {
        await fetch('/api/theme', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId, theme: newTheme }),
        });
        console.log('Theme updated successfully');
      } catch (error) {
        console.error('Ошибка при обновлении темы:', error);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="loadingScreen">
        <p>Загрузка...</p>
      </div>
    );
  }

  return (
    <Router>
      <div className="layout">
        <button onClick={toggleTheme} className="toggleButton" style={{ display: 'none' }}>
          Переключить тему
        </button>
        <Routes>
          <Route element={<Layout coins={coins} incomePerHour={incomePerHour} role={role} theme={theme} />}> {/* Передача props в Layout */}
            <Route
              index
              element={<Home userId={userId} setCoins={setCoins} setLevel={setLevel} role={role} />}
            />
            <Route
              path="income"
              element={<Income userId={userId} incomePerHour={incomePerHour} coins={coins} setIncomePerHour={setIncomePerHour} setCoins={setCoins} />}
            />
            <Route path="rank" element={<Rank />} />
            <Route path="settings" element={<Settings theme={theme} toggleTheme={toggleTheme} />} />
            {role === 'admin' && (
              <Route path="admin" element={<AdminPanel />} />
            )}
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
